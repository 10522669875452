<style scoped>
table {
  width: 100%;
  display: table;
}
th,
td {
  border: 1px solid gray;
  padding: 10px 15px;
  overflow: hidden;
}
</style>
<template>
  <div id="Books" class="px-5 py-3 dashboard">
    <!-- component -->
    <!-- This is an example component -->

    <!-- component -->
    <button
      class="btn text-white rounded-md px-4 py-2 hover:bg-blue-700 transition"
      @click="openModal('modal')"
    >
      Create New
    </button>

    <div
      id="modal"
      class="modal fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-center agora-red text-white text-xl rounded-t-md px-4 py-2"
        >
          <h3>New Book</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="createNew()" enctype="multipart/form-data">
            <div>
              <label for="title" class="font-bold">Title:</label>

              <input
                v-model="newBook.title"
                name="title"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div>
              <label for="author" class="font-bold">Author:</label>

              <input
                v-model="newBook.author"
                name="author"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div class="w-full">
              <div class="w-full">
                <label for="category" class="font-bold">Category:</label>

                <input
                  v-model="newBook.category"
                  name="category"
                  type="text"
                  class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                />
              </div>
              <div class="w-full">
                <div class="flex justify-between py-5">
                  <label class="font-bold">Available At:</label>
                  <div
                    class="bg-blue-400 w-8 h-8 text-center rounded-md text-gray-100 pt-1"
                    @click="addLocation()"
                  >
                    <i class="fa fa-plus"></i>
                  </div>
                </div>

                <div v-if="newBook.availableAt.length">
                  <ul class="locations">
                    <li class="justify-between">
                      <span>Icon</span>
                      <span>URL</span>
                      <span>Location</span>
                      <span>Price</span>
                      <span class="block w-20 h-8"></span>
                    </li>
                    <li
                      v-for="(item, index) in newBook.availableAt"
                      :key="index"
                      class="items-center"
                    >
                      <input
                        v-model="item.fav"
                        name="fav"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        v-model="item.url"
                        name="url"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        v-model="item.location"
                        name="location"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        step="0.01"
                        v-model="item.price"
                        name="price"
                        type="number"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <div
                        class="agora-red w-24 h-8 text-center rounded-md text-gray-100 pt-1"
                        @click="deleteLocation(index)"
                      >
                        <i class="fa fa-trash-can"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <div class="text-center py-3 rounded-md bg-gray-200 mb-5">
                    No Locations Added Yet
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label for="overview" class="font-bold">Overview:</label>

              <br />
              <br />
              <vue-editor v-model="newBook.overview"></vue-editor>
              <br />
            </div>
            <div>
              <label for="aboutAuthor" class="font-bold">About Author:</label>

              <br />
              <br />
              <vue-editor v-model="newBook.aboutAuthor"></vue-editor>
              <br />
            </div>
            <div>
              <label for="audio">Audio Sample URL</label>
              <br />
              <input
                v-model="newBook.audio"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
              <br />
              <label for="attribution">Sample Attribution</label>
              <input
                v-model="newBook.attribution"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div>
              <label for="image" class="font-bold">Book Image</label>
              <br />
              <button
                @click="uploadTrigger"
                class="my-5 px-5 py-3 agora-red text-gray-100 rounded-md"
              >
                Upload Image
              </button>
              <div id="uploadedImageHolder">
                <br />
                <img
                  id="uploadedImage"
                  src=""
                  alt="Uploaded image"
                  style="display: none"
                />
                <br />
              </div>
              <input
                ref="imageUploadBtn"
                @change="previewImage"
                name="image"
                type="file"
                class="hidden text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <button
              class="btn agora-red px-3 py-2 rounded-md text-white"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <div
      id="editModal"
      class="modal fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
    >
      <div
        class="relative top-10 mx-auto shadow-lg rounded-md bg-white max-w-md"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-center agora-red text-white text-xl rounded-t-md px-4 py-2"
        >
          <h3>Edit Book</h3>
          <button @click="closeModal()">x</button>
        </div>

        <!-- Modal body -->
        <div class="p-4">
          <form @submit.prevent="updateBook(newBook.id)">
            <div>
              <label for="title" class="font-bold">Title:</label>

              <input
                v-model="newBook.title"
                name="title"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div>
              <label for="author" class="font-bold">Author:</label>

              <input
                v-model="newBook.author"
                name="author"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div class="flex w-full gap-x-5 justify-between">
              <div class="w-full">
                <div class="flex justify-between py-5">
                  <label class="font-bold">Available At:</label>
                  <div
                    class="bg-blue-400 w-8 h-8 text-center rounded-md text-gray-100 pt-1"
                    @click="addLocation()"
                  >
                    <i class="fa fa-plus"></i>
                  </div>
                </div>

                <div v-if="newBook.availableAt.length">
                  <ul class="locations">
                    <li class="justify-between">
                      <span>Icon</span>
                      <span>Location</span>
                      <span>Price</span>
                      <span class="block w-20 h-8"></span>
                    </li>
                    <li
                      v-for="(item, index) in newBook.availableAt"
                      :key="index"
                      class="items-center"
                    >
                      <input
                        v-model="item.fav"
                        name="fav"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        v-model="item.url"
                        name="url"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        v-model="item.location"
                        name="location"
                        type="text"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <input
                        step="0.01"
                        v-model="item.price"
                        name="price"
                        type="number"
                        class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
                      />
                      <div
                        class="agora-red w-24 h-8 text-center rounded-md text-gray-100 pt-1"
                        @click="deleteLocation(index)"
                      >
                        <i class="fa fa-trash-can"></i>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <div class="text-center py-3 rounded-md bg-gray-200 mb-5">
                    No Locations Added Yet
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label for="overview" class="font-bold">Overview:</label>

              <br />
              <br />
              <vue-editor v-model="newBook.overview"></vue-editor>
              <br />
            </div>
            <div>
              <label for="aboutAuthor" class="font-bold">About Author:</label>

              <br />
              <br />
              <vue-editor v-model="newBook.aboutAuthor"></vue-editor>
              <br />
            </div>
            <div>
              <label for="audio">Audio Sample URL</label>
              <br />
              <input
                v-model="newBook.audio"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
              <br />
              <label for="attribution">Sample Attribution</label>
              <input
                v-model="newBook.attribution"
                type="text"
                class="text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5"
              />
            </div>
            <div>
              <label for="image" class="font-bold">Book Image</label>
              <br />
              <button
                @click="uploadTrigger"
                class="my-5 px-5 py-3 agora-red text-gray-100 rounded-md"
              >
                Upload Image
              </button>
              <div id="uploadedImageHolder">
                <br />
                <img
                  id="uploadedImageEdit"
                  :src="newBook.img"
                  alt="Uploaded image"
                />
                <br />
              </div>
              <input
                ref="imageUploadBtn"
                @change="previewImage"
                name="image"
                type="file"
                class="hidden text-sm bg-gray-100 shadow-lg rounded-md shadow-inner block w-full my-3 h-10 px-5 inEdit"
              />
            </div>
            <button
              class="btn agora-red px-3 py-2 rounded-md text-white"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="mx-auto">
      <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
              <table
                class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-100"
              >
                <thead class="bg-gray-100 dark:bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">ID</div>
                    </th>
                    <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Author
                    </th>

                    <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Locations
                    </th>
                    <th scope="col" class="p-4">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white divide-y divide-gray-200 dark:divide-gray-700"
                >
                  <tr
                    class="hover:bg-gray-100"
                    v-for="p in getBooks"
                    :key="p.id"
                  >
                    <td class="p-4 w-4">
                      <div class="flex items-center">
                        {{ p.id }}
                      </div>
                    </td>
                    <td
                      class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                    >
                      {{ p.title }}
                    </td>
                    <td
                      class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                    >
                      {{ p.author }}
                    </td>

                    <td
                      class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                    >
                      <ul>
                        <li
                          v-for="(item, index) in p.availableAt"
                          :key="'at' + index"
                        >
                          <span>{{ item.location }} - ${{ item.price }}</span>
                        </li>
                      </ul>
                    </td>
                    <td
                      class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap"
                    >
                      <button
                        class="bg-yellow-400 text-white font-bold px-3 py-2 rounded-md"
                        @click="
                          openModal('editModal');
                          fillInfo(p.id);
                        "
                      >
                        Edit
                      </button>
                      |
                      <button
                        class="bg-red-600 text-white font-bold px-3 py-2 rounded-md"
                        @click="deleteBook(p.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from "../../firebase";

import {
  getStorage,
  ref as stRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getBooks"]),
  components: {
    VueEditor,
  },
  data() {
    return {
      bookId: null,
      img: null,
      uploadValue: null,
      imageData: null,

      newBook: {
        attribution: "",
        audio: "",
        availableAt: [],
        title: "",
        category: "",
        price: "",
        overview: "",
        author: "",
        aboutAuthor: "",
        rating: 0,
        img: null,
        enabled: false,
      },
      books: [],
    };
  },
  methods: {
    deleteLocation(idx) {
      this.newBook.availableAt.splice(idx, idx + 1);
    },
    addLocation() {
      this.newBook.availableAt.push({
        location: "",
        price: 0,
      });
    },
    uploadTrigger(e) {
      e.preventDefault();
      this.$refs.imageUploadBtn.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      const fileBtn = event.target;

      if (file) {
        const reader = new FileReader();

        reader.onload = function (event) {
          const imageData = event.target.result;
          let imageElement = null;
          if (fileBtn.classList.contains("inEdit")) {
            imageElement = document.getElementById("uploadedImageEdit");
          } else {
            imageElement = document.getElementById("uploadedImage");
          }

          imageElement.src = imageData;
          imageElement.style.display = "block";
        };

        reader.readAsDataURL(file);
        this.imageData = event.target.files[0];
      } else {
        console.log("No file selected!");
      }
    },
    onUpload() {
      this.$loading(true);
      let bytes = this.imageData;

      //================================================
      const storage = getStorage(app);
      const storageRef = stRef(storage, "files/" + bytes.name);
      const uploadTask = uploadBytesResumable(storageRef, bytes);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log("Upload has an error: " + error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.img = downloadURL;
          });
          this.$loading(false);
        }
      );
      //================================================
    },
    openModal(modalId) {
      let modal = document.getElementById(modalId);
      modal.classList.remove("hidden");
    },
    closeModal() {
      let modals = document.getElementsByClassName("modal");
      for (let i = 0; i < modals.length; i++) {
        modals[i].classList.add("hidden");
      }
    },
    fillInfo(id) {
      let filteredBooks = this.getBooks.filter((item) => {
        return item.id == id;
      });
      this.newBook = filteredBooks[0];
    },
    createNew() {
      this.$loading(true);
      let authData = {
        username: this.$store.state.user.email,
        password: this.$store.state.user.id,
        grant_type: "password",
      };
      this.$Axios
        .post(
          "https://staging.agorapertuk.site/wp-json/jwt-auth/v1/token",
          authData,
          {
            Headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          let token = res.data.token;
          let imageFormData = new FormData();
          imageFormData.append(
            "title",
            "agora_voice_img_" + Math.random() * 9999999
          );
          imageFormData.append("caption", "agora_voice_img");
          imageFormData.append("file", this.imageData);

          this.$Axios
            .post(
              "https://staging.agorapertuk.site/wp-json/wp/v2/media",
              imageFormData,
              {
                headers: {
                  Authorization: "Bearer " + token, // 'Bearer' should start with an uppercase 'B'
                  "Content-Type": "multipart/form-data", // 'Content-Type' should start with an uppercase 'C'
                },
              }
            )
            .then((res2) => {
              this.img = res2.data.source_url;
              this.newBook.img = this.img;

              this.$store.commit("addBook", this.newBook);
              this.img = null;
              this.newBook = {
                title: "",
                category: "",
                price: "",
                overview: "",
                author: "",
                aboutAuthor: "",
                rating: 5,
                img: null,
                enabled: false,
              };
              this.closeModal();
            })
            .catch((err2) => {
              console.log(err2);
            });
          this.$loading(false);
        })
        .catch((err) => {
          console.log(err);
          this.$loading(false);
        });
    },
    updateBook() {
      let authData = {
        username: this.$store.state.user.email,
        password: this.$store.state.user.id,
        grant_type: "password",
      };

      if (this.imageData) {
        console.log("Will upload");
        this.$loading(true);
        this.$Axios
          .post(
            "https://staging.agorapertuk.site/wp-json/jwt-auth/v1/token",
            authData,
            {
              Headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            let token = res.data.token;
            let imageFormData = new FormData();
            imageFormData.append(
              "title",
              "agora_voice_img_" + Math.random() * 9999999
            );
            imageFormData.append("caption", "agora_voice_img");
            imageFormData.append("file", this.imageData);

            this.$Axios
              .post(
                "https://staging.agorapertuk.site/wp-json/wp/v2/media",
                imageFormData,
                {
                  headers: {
                    Authorization: "Bearer " + token, // 'Bearer' should start with an uppercase 'B'
                    "Content-Type": "multipart/form-data", // 'Content-Type' should start with an uppercase 'C'
                  },
                }
              )
              .then((res2) => {
                this.img = res2.data.source_url;
                this.newBook.img = this.img;
                this.$store.commit("updateBook", {
                  id: this.newBook.id,
                  data: this.newBook,
                });
                this.newBook = {
                  title: "",
                  category: "",
                  price: "",
                  overview: "",
                  author: "",
                  aboutAuthor: "",
                  rating: 5,
                  img: null,
                  enabled: false,
                };
                this.$loading(false);
                this.closeModal();
              })
              .catch((err2) => {
                console.log(err2);
                this.$loading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            this.$loading(false);
          });
      } else {
        this.$store.commit("updateBook", {
          id: this.newBook.id,
          data: this.newBook,
        });
        this.newBook = {
          title: "",
          category: "",
          price: "",
          overview: "",
          author: "",
          aboutAuthor: "",
          rating: 5,
          img: null,
          enabled: false,
        };
        this.$loading(false);
        this.closeModal();
      }
    },
    deleteBook(id) {
      this.$store.commit("deleteBook", id);
    },
  },

  mounted() {},
};
</script>

<style>
</style>