<template>
  <div>
    <div class="banner shadow-lg"></div>
    <div class="wrapper BookDetails" v-if="book">
      <br />
      <h2 class="text-4xl font-bold">{{ book.title }}</h2>
      <br />
      <div
        class="bookHolder mx-auto w-full lg:w-4/5 overflow-hidden rounded-lg shadow-lg px-5 py-7 lg:flex items-center gap-x-5"
      >
        <div class="imgHolder lg:w-2/6 h-auto rounded-lg overflow-hidden">
          <img :src="'/Images/' + book.title + '.jpg'" alt="" />
        </div>
        <div class="details lg:w-4/6 my-2 px-5">
          <p v-html="shortDescription"></p>
          <!-- <a href="#overview" class="border-red-400 text-red-400 border-2 font-bold  inline-block w-8 h-8 flex items-center justify-center text-center rounded-full ml-auto">
            <i class="fa fa-arrow-down"></i>
          </a> -->

          <br />
          <hr />
          <br />
          <audio class="mx-auto w-5/6 md:w-1/2" controls>
            <source :src="book.audio" type="audio/mpeg" />
          </audio>
          <br />
          <div class="text-center">
            Audio sample provided by: {{ book.attribution }}
            <i class="fa fa-copyright"></i>
          </div>
          <br />
        </div>
      </div>
    </div>
    <div id="detailTabs" class="wrapper">
      <vue-tabs>
        <v-tab title="Overview">
          <div
            id="overview"
            class="shadow-lg rounded-lg my-10 py-2 px-5 lg:py-5 lg:px-10"
            v-if="book"
          >
            <p class="text-xl font-bold">{{ book.title }}</p>
            <div v-html="book.overview"></div>
          </div>
        </v-tab>

        <v-tab title="About the Author">
          <div
            id="About"
            class="shadow-lg rounded-lg my-10 py-2 px-5 lg:py-5 lg:px-10"
            v-if="book"
          >
            <p class="text-xl font-bold">{{ book.title }}</p>
            <div v-html="book.aboutAuthor"></div>
          </div>
        </v-tab>
      </vue-tabs>
    </div>
    <div id="sellers" class="wrapper mb-5">
      <h2 class="uppercase text-center font-bold text-4xl text-agora-red mb-5">
        Get this book and more at
      </h2>
      <div class="purchaseDetails items-center justify-center">
        <div
          v-for="(item, index) in book.availableAt"
          :key="'booksat' + index"
          class="flex justify-center "
        >
          <a
            class="flex items-center gap-1 justify-center block w-full locationLink border-4 p-3 rounded-md font-bold"
            target="_blank"
            :href="item.url"
          >
            <img
              :src="item.fav"
              class="w-10 h-10"
              :alt="item.location + ' favicon'"
            />
            <span>{{ item.location }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="whyVoice bg-gray-100 w-full py-10">
      <h2 class="text-4xl font-bold text-center">Why Agora Voice?</h2>
      <div class="py-20">
        <ul
          class="grid gap-5 lg:grid-cols-2 lg:w-1/2 gap-y-10 text-lg mx-auto px-10 lg:px-0"
        >
          <div class="flex gap-1">
            <div class="pt-1 w-10 h-5">
              <div
                class="w-4 h-4 bg-gray-100 border-orange-400 border-4 rounded-full p-0 m-0"
              ></div>
            </div>
            <li>
              Enjoy and listen to the voice of Kurdish and other distinctive
              literature all over the world.
            </li>
          </div>
          <div class="flex gap-1">
            <div class="pt-1 w-10 h-5">
              <div
                class="w-4 h-4 bg-gray-100 border-orange-400 border-4 rounded-full p-0 m-0"
              ></div>
            </div>
            <li>
              Explore the richness of literature from hidden cultures with
              hidden stories and hidden lives.
            </li>
          </div>
          <div class="flex gap-1">
            <div class="pt-1 w-10 h-5">
              <div
                class="w-4 h-4 bg-gray-100 border-orange-400 border-4 rounded-full p-0 m-0"
              ></div>
            </div>
            <li>
              Experience a world-class mobile application and digital platform
              dedicated to audiobooks.
            </li>
          </div>
          <div class="flex gap-1">
            <div class="pt-1 w-10 h-5">
              <div
                class="w-4 h-4 bg-gray-100 border-orange-400 border-4 rounded-full p-0 m-0"
              ></div>
            </div>
            <li>
              Expand your knowledge with masterpieces of non-English literature,
              now available in English.
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueTabs, VTab } from "vue-nav-tabs";
//you can also import this in your style tag
import "vue-nav-tabs/themes/vue-tabs.css";
//component code

export default {
  computed: mapGetters(["getBooks"]),
  components: {
    VueTabs,
    VTab,
  },
  data() {
    return {
      book: null,
      shortDescription: null,
    };
  },
  methods: {
    bookById(id) {
      return this.getBooks.filter((item) => {
        return item.id == id;
      })[0];
    },
    removeHTML(html_txt) {
      let cleat_txt = html_txt.replace(/<[^>]*>/g, "");
      if (cleat_txt.length > 200) {
        return cleat_txt.substring(0, 270) + ". . .";
      } else {
        return cleat_txt;
      }
    },
  },
  mounted() {

    this.$loading(true);

    setTimeout(() => {
      this.book = this.bookById(this.$route.params.id);
      this.shortDescription = this.removeHTML(this.book.overview);
      this.$loading(false);
    }, 1000);
  },
  params: ["id"],
};
</script>

<style>
</style>