<template>
  <div id="app">
    <sideBar />
    <div class="menu" :class="{ toggled: toggled }">
      <ul>
        <li>
          <a @click="toggled = false" href="/#Project">Project</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Catalog">Catalog</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Company">Company</a>
        </li>
        <li>
          <a @click="toggled = false" href="/#Why">Why</a>
        </li>

        <li>
          <a @click="toggled = false" href="/#Contact">Contact</a>
        </li>
      </ul>
    </div>
    <div class="mx-auto">
      <nav
        class="border-bottom-1 py-5"
        :class="{ atHome: $route.name == 'Home' }"
      >
        <div class="flex items-center justify-between w-full">
          <div class="flex items-center gap-x-5">
            <img
              src="https://s3.amazonaws.com/soundwiseinc/soundcasts/1666690540p.png"
              alt="Agora Voice Logo"
              class="w-10 h-10 rounded-full"
            />
            <ul
              class="desktopNav hidden gap-5 lg:flex items-center text-xs xl:text-base"
            >
              <li>
                <a @click="toggled = false" href="/#Project">Project</a>
              </li>
              <li>
                <a @click="toggled = false" href="/#Catalog">Catalog</a>
              </li>
              <li>
                <a @click="toggled = false" href="/#Company">Company</a>
              </li>

              <li>
                <a @click="toggled = false" href="/#Why">Why</a>
              </li>

              <li>
                <a @click="toggled = false" href="/#Contact">Contact</a>
              </li>
            </ul>
          </div>
          <div
            class="hamburger block lg:hidden"
            :class="{ toggled: toggled }"
            @click="toggled = !toggled"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <br />
        <hr />
      </nav>
      
      <router-view />
    </div>
    <footer>
      <div class="social">
        <div>
          <a href=""> <i class="fab fa-facebook-square"></i></a>
          <a href=""> <i class="fab fa-linkedin"></i></a>
          <a href=""> <i class="fab fa-square-instagram"></i></a>
          <a href=""> <i class="fab fa-youtube-square"></i></a>
        </div>
      </div>
      <div class="w-full text-center">
        Copyright © {{ new Date().getFullYear() }} - Agora Vision Company for
        Digital Broadcasting and Publishing Ltd.
      </div>
    </footer>
  </div>
</template>
<script>
import sideBar from './components/sidebar.vue'
export default {
  components: {
    sideBar,
  },
  data() {
    return {
      toggled: false,
    };
  },
};
</script>
<style>
</style>
