// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/database'
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAGqRe5pTp5Hww3t8hUTodLrZT-ZGyH3KU",
    authDomain: "agora-voice-website.firebaseapp.com",
    databaseURL: "https://agora-voice-website-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "agora-voice-website",
    storageBucket: "agora-voice-website.appspot.com",
    messagingSenderId: "369368081496",
    appId: "1:369368081496:web:822c62c23ce96d2ee9162d"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);


