import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Book from '../views/Book.vue'
import Login from '../views/admin/Login.vue'
import guard from './auth-guard'
import Books from '../views/admin/Books.vue'
import VueBodyClass from 'vue-body-class';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/book/:id',
    name: 'Book',
    component: Book
  },
  {
    path: '/admin/books',
    name: 'Books',
    component: Books,
    beforeEnter: guard,
    meta: { bodyClass: 'admin' }

  },
  {
    path: '/login',
    name: "login",
    component: Login,
    meta: { bodyClass: 'admin' }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });