import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLoading from 'vuejs-loading-plugin'
import VueToastify from "vue-toastify";
import loader from '@/components/loader'
import axios from 'axios'


Vue.prototype.$Axios = axios;

Vue.prototype.imgHeaders = {
  
}

Vue.config.productionTip = false
Vue.use(VueToastify,{
  position: 'center-center',
  backdrop: 'rgba(255,255,255,0.8)'
});
Vue.use(VueLoading, {
  
  loading: false, // default false
  customLoader: loader, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  
})
new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.$store.dispatch('loadBooks');
   
  }
}).$mount('#app')


