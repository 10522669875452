
<template>
  <div class="home">
    <div class="hidden sm:block" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide>
          <div class="oneSlide">
            <img src="/Images/the company.jpg" alt="The Company" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/principles.jpg" alt="Principles" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/why us.jpg" alt="Why Agora?" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/contact.jpg" alt="Contact Us" />
          </div>
        </slide>
      </carousel>
    </div>
    <div class="block sm:hidden" style="direction: ltr !important">
      <carousel
        :perPage="1"
        :paginationPosition="'bottom-overlay'"
        :navigationEnabled="true"
        navigationNextLabel="<i class='fa fa-arrow-right'></i>"
        navigationPrevLabel="<i class='fa fa-arrow-left'></i>"
      >
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/the company.jpg" alt="The Company" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/principles.jpg" alt="Principles" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/why us.jpg" alt="Why Agora?" />
          </div>
        </slide>
        <slide>
          <div class="oneSlide">
            <img src="/Images/mobile/contact.jpg" alt="Contact Us" />
          </div>
        </slide>
      </carousel>
    </div>
    <br />

    <div id="Project">
      <br />
      <div class="wrapper shadow-lg rounded-md my-2">
        <h2 class="text-2xl font-bold mb-4">The Project</h2>
        <div class="lg:grid grid-cols-5">
          <div class="col-span-3">
            <p>
              Agora Voice is the first audiobook platform dedicated to Kurdish
              and other under-represented cultures from the world for English
              listeners around the world.
            </p>

            <p>
              It only records and publishes books that are either written in
              non-English languages but have been translated into English or
              written in English by non-English authors, describing their
              livelihood, culture, sufferings, stories, ..etc of their native
              culture.
            </p>

            <p>
              It comes with world-class features and quality contents; contents
              that otherwise would be entirely hidden from the world.
            </p>
          </div>
          <div class="justify-self-center col-span-2">
            <img
              src="/Images/books.jpg"
              alt="Translated books"
              class="mx-auto pt-5 lg:pt-0"
              style="width: 400px"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="Catalog">
      <div class="mainImg py-20 wrapper">
        <div class="catalog">
          <br />
          <h2 class="text-3xl font-bold">Catalog</h2>
          <br />
          <hr />
          <br />
          <div class="cardHolder lg:flex gap-x-5 w-full">
            <div
              v-for="b in getBooks"
              :key="b.id"
              class="card w-full lg:w-64 pb-5 my-3 rounded-lg shadow-lg overflow-hidden text-center"
            >
              <div>
                <img
                  :src="'/Images/' + b.title + '.jpg'"
                  alt="Book Image"
                  class="w-full lg:w-64 lg:h-64"
                />

                <div class="info">
                  <br />
                  <h3 class="text-lg font-bold text-gray-800">{{ b.title }}</h3>

                  <br />
                  <router-link
                    :to="'/book/' + b.id"
                    class="btn text-gray-100 py-3 px-4 rounded-md"
                  >
                    Read More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="Company">
      <div class="wrapper shadow-lg rounded-md my-2 lg:flex">
        <div class="lg:w-2/3">
          <h2 class="text-2xl font-bold mb-4">The Company</h2>
          <div>
            <p>
              <a target="_blank" href="https://agoravision.site" class="siteColor">Agora Vision</a> 
              was founded to use creative thinking, great designs,
              and computing advancements to fill a gap in Kurdish and other
              distinctive literature around the world. It is the legal entity
              that owns and operates
              <a target="_blank" href="https://agoradeng.site" class="siteColor">Agora Deng</a>
              and
              Agora Voice,
              both of which are audiobook platforms dedicated to Kurdish and
              other distinctive literature in the world, in Kurdish and English.
              Among its product lines are also
              <a target="_blank" href="https://agorapertuk.site" class="siteColor">Agora Pertuk</a
              >, a channel to make Kurdish books available globally in a digital
              format using the latest ebook technology and
              <a target="_blank" href="https://agorawarger.site" class="siteColor">Agora Warger</a
              >, an in-house, multilingual translation system dedicated to
              literature translation.<br /><br />The Company is officially
              incorporated in KRG, the Kurdistan Regional Government of Iraq and
              British Columbia, Canada as Agora Vision Company for Digital
              Broadcasting and Publishing Ltd. However, it serves a global
              market.
            </p>
          </div>
          <br />
          <h2 class="text-2xl font-bold mb-4">The Mission</h2>
          <div>
            <p>
              Increasing the impact of Kurdish and other distinctive literature
              around the world using audiobook apps and digital platforms.
            </p>
          </div>
          <br />
          <h2 class="text-2xl font-bold mb-4">The Vision</h2>
          <div>
            <p>
              Achieving global recognition of Kurdish and other distinctive
              literature at the level of motion picture productions and awards.
            </p>
          </div>
        </div>
        <div class="lg:w-1/3">
          <img src="/Images/bulb.jpg" alt="The Company" loading="lazy" />
        </div>
      </div>
    </div>
    <div id="Why">
      <div class="wrapper shadow-lg rounded-md my-5 lg:flex">
        <div class="lg:w-1/3">
          <img src="/Images/monument.jpg" alt="Team " />
        </div>
        <div class="mt-5 lg:w-2/3">
          <h2 class="text-2xl font-bold mb-4">Why Agora?</h2>
          <div>
            <p>
              Agora is an ancient Greek word that means “a gathering space”. It
              refers to a central, public space that is the center of bringing
              different perspectives on life together including thought leaders,
              merchants, artists, …etc. It was chosen as a name for Agora Vision
              company for its relevance to the company’s mission as a platform
              for creating information exchange and its similarity to a central
              space in Sulaimani, the city that Agora Vision company was founded
              in.
            </p>
            <br />
            <p>
              For more details on the word “agora”, please, check the following
              <a
                target="_blank"
                href="https://en.wikipedia.org/wiki/Agora"
                class="italic text-blue-500"
                >Wikipedia page</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="Contact" class="Contact">
      <div class="wrapper shadow-lg rounded-md my-5">
        <div>
          <form @submit.prevent="sendEmail" ref="form" id="contactForm">
            <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

            <div class="aboveForm">
              <p>
                To stop by for a quick chat over a cup of Kurdish tea, visit us
                at the following address:
              </p>

              <div class="text-xs" data-v-51e62307="">
                <br data-v-51e62307="" />
                <p class="font-bold" data-v-51e62307="">
                  Working Hours: 09:00 AM to 01:00 PM
                </p>
                <br data-v-51e62307="" />
                <h3 class="font-bold text-lg siteColor" data-v-51e62307="">
                  Iraq Address:
                </h3>
                <br data-v-51e62307="" />
                <span class="text-sm" data-v-51e62307=""
                  >Heeran Building, next to Azadi Mall<br />3rd Floor, Office
                  No. 5 &amp; 6 <br />
                  Sulaimani, Kurdistan Region of Iraq</span
                >
                <br data-v-51e62307="" />
                <span class="text-sm font-bold text-gray-600" data-v-51e62307=""
                  ><a href="tel:009647702000545" data-v-51e62307=""
                    >+964 770 2000 545</a
                  >
                  |
                  <a href="tel:009647502000545" data-v-51e62307=""
                    >+964 750 2000 545</a
                  ></span
                >
                <br data-v-51e62307="" />
                <br data-v-51e62307="" />
                <h3 class="font-bold text-lg siteColor" data-v-51e62307="">
                  Canada Address:
                </h3>
                <br data-v-51e62307="" />
                <span class="text-sm" data-v-51e62307=""
                  >1200 – 900 West Hastings St.<br />Vancouver, British
                  Columbia<br />V6C 1E5<br />Canada</span
                >
                <br data-v-51e62307="" />
                <span class="text-sm font-bold text-gray-600" data-v-51e62307=""
                  ><a href="tel:0017767781476" data-v-51e62307=""
                    >+1 776 778 1476</a
                  ></span
                >
                <br data-v-51e62307="" />
              </div>

              <p>Or, feel free to get in touch using the following form:</p>
            </div>
            <label for="user_name"> Full Name </label>
            <input
              required
              v-model="contact.name"
              type="text"
              name="user_name"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="user_email">E-mail </label>
            <input
              required
              v-model="contact.mail"
              type="email"
              name="user_email"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="user_phone"> Phone Number </label>
            <input
              required
              v-model="contact.phone"
              type="tel"
              name="user_phone"
              class="block w-full h-8 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            />
            <label for="message" class="block"> Message </label>
            <textarea
              required
              v-model="contact.message"
              name="message"
              class="block w-full h-24 rounded shadow-lg my-2 px-2 outline-none transition hover:shadow-md"
            ></textarea>
            <button
              class="mt-3 block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition"
            >
              Send
            </button>
          </form>
        </div>
        <div class="w-50">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.5323177804235!2d45.432587515609875!3d35.56525094393079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002dc3c9af9dcb%3A0x1382709528a56a1e!2zQWdvcmEgVmlzaW9uINim2Kfar9uG2LHYpyDapNuM2pjZhg!5e0!3m2!1sen!2siq!4v1657790412965!5m2!1sen!2siq"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import emailjs from "@emailjs/browser";
import { Carousel, Slide } from "vue-carousel";
export default {
  computed: mapGetters(["getBooks"]),
  name: "Home",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      filteredPosts: null,
      books: [],
      contact: {
        name: null,
        mail: null,
        phone: null,
        message: null,
      },
    };
  },
  methods: {
    sendEmail() {
      this.$loading(true);
      emailjs
        .sendForm(
          "service_it8ziaq",
          "template_fro9gmk",
          this.$refs.form,
          "user_Mhdpo0N7SP8VnDjdVXPda"
        )
        .then(
          (result) => {
            console.log(result);
            this.contact = {
              name: null,
              mail: null,
              phone: null,
              message: null,
            };
            this.$loading(false);
            this.$vToastify.success("Email was sent successfully.", "Success!");
          },
          (error) => {
            console.log(error);
            this.$loading(false);
            this.$vToastify.error(
              "Please contact website administrator.",
              "An error occurred."
            );
          }
        );
    },
  },
  mounted() {},
};
</script>


