<style scoped>
.LoginForm {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: auto;
  transform: translate(-50%, -50%);
}
</style>
<template>
  <div class="LoginForm">
    <div
      class="container rounded-md shadow-lg bg-blue-300 text-white px-3 py-5"
    >
      <img src="/Images/logo-white.png" alt="Logo" class="w-24 m-auto" />
      <br />
      <form class="login" @submit.prevent="signIn">
        <label for="email">Email: </label><br />
        <input
          v-model="user.email"
          type="text"
          name="email"
          class="text-gray-800 rounded-md shadow-lg my-3 py-1 w-full"
        /><br />
        <label for="password">Password</label><br />
        <input
          v-model="user.password"
          type="password"
          name="password"
          class="text-gray-800 rounded-md shadow-lg my-3 py-1 w-full"
        />
        <br />
        <button type="submit" class="btn rounded-md shadow-lg my-3 py-1 w-full">
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { app } from "../../firebase";
export default {
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
    };
  },
  created() {
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch("autoSignIn", user);
      } else {
        this.$vToastify.error(
          "Credentials Error",
          "An error occurred."
        );
      }
    });
  },
  methods: {
    signIn() {
      this.$store.dispatch("signUserIn", {
        email: this.user.email,
        password: this.user.password,
      });
    },
  },
};
</script>

<style>
</style>