<template>
    <div id="loader">
        <img src="https://apptimize.com/wp-content/uploads/2015/10/Ajax-loader.gif" alt="Loading" style="width:90px">
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>