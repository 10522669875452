import Vue from 'vue'
import Vuex from 'vuex'
import {app} from '../firebase'
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    about:null,
    books:[],
    user:null
    
  },
  getters: {
    
    getBooks:(state)=>state.books,
    getAbout:(state)=>state.about,
    getUser:(state)=>state.user
  }
  ,
  actions: {
    loadBooks({commit}){
      app.database().ref('data/Books').once('value')
      .then((data)=>{
        const dataBooks =[] ;
        const obj = data.val();

        for (let key in obj){
          dataBooks.push({"id":key,...obj[key]})
        }
        
        commit('setBooks',dataBooks);
      })
      .catch(
        (error)=>{
          console.log(error)
        }
      );
      
    },
    signUserIn({commit},payload) {
      app.auth().signInWithEmailAndPassword(payload.email,payload.password)
      .then(user=>{
        
        const newUser = {
          uid:user.user.multiFactor.user.uid,
          email:user.user.multiFactor.user.email
        }
        
        commit('setUser',newUser)
      }).catch(error=>{
        console.log(error)
      });

    },
    autoSignIn({commit},payload){
      
      commit('setUser',{id:payload.uid,email:payload.email})
    },
    logOut({commit})
    {
      app.auth().signOut();
      commit('setUser',null);
      location.reload();
    }
  },
  mutations: {
    setUser(state,newUser){
      state.user = newUser;
      router.push("/admin/Books")
    },
   
    
    addBook(state, newBook){
      app.database().ref('data/Books').push(newBook);
      this.dispatch('loadBooks');
      
    },
    deleteBook(state, id){
      app.database().ref('data/Books').child(id).remove();
      this.dispatch('loadBooks');
      
    },
    updateBook(state,data){
      
      app.database().ref('data/Books').child(data.id).set({...data.data});
      this.dispatch('loadBooks');
      
    },
    updateAbout(state,data){
      
      app.database().ref('data/About').child(data.id).set({...data.data});
      this.dispatch('loadBooks');
      
    },
    setBooks(state,payload){
      state.books=payload;
      
    },


  }
})
